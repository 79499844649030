import { Navbar } from './Navbar';
import { useState } from "react";
import Slider from "react-slick";
import EmilWebsite from '../imgs/portofolio/emil-chitu.png';
import GerolaWebsite from '../imgs/portofolio/gerola.png';
import RaduWebsite from '../imgs/portofolio/radu-simionovici.png';
import WebflowLogo from '../imgs/skills/webflow-logo.png';
import FigmaLogo from '../imgs/skills/figma-logo.png';

export const Portofolio = () => {
    const gerolaProject={
        img: GerolaWebsite,
        title: "Gerola",
        descrpiton:"Gerola is a concept design of a bio produtcs comapny. All graphics were made by me using figma.",
        techLogo: [WebflowLogo,FigmaLogo],
        link:"https://www.gerola.ro/"
    }

    const emilProject={
        img: EmilWebsite,
        title: "Emil Chițu",
        descrpiton:"'Emil Chițu' is a concept design of a lawyer. All graphics were made by me using figma.",
        techLogo: [WebflowLogo,FigmaLogo],
        link:"https://www.avocatchitu.ro/"
    }

    const raduProject={
        img: RaduWebsite,
        title: "Radu Simionovici",
        descrpiton:"'Radu Simionovici' is a concept design of a lawyer. All graphics were made by me using figma.",
        techLogo:[WebflowLogo,FigmaLogo],
        link:"https://www.avocatachizitii.ro/"
    }

    const projects = [gerolaProject,emilProject,raduProject,emilProject,gerolaProject,raduProject];
    const NextArrow = ({ onClick }) => {
        return (
          <div className="arrow next" onClick={onClick}>
          </div>
        );
      };
    
      const PrevArrow = ({ onClick }) => {
        return (
          <div className="arrow prev" onClick={onClick}>
            l
          </div>
        );
      };
    
      const [imageIndex, setImageIndex] = useState(0);
    
      const settings = {
        infinite: true,
        lazyLoad: true,
        speed: 300,
        slidesToShow: 3,
        centerMode: true,
        centerPadding: 10,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        beforeChange: (current, next) => setImageIndex(next),
      };
    

    return (
        <section id="portofolio">
        <Navbar />
        <div class="container-portofolio">
            <div class="container-center">
            <Slider {...settings}>
        {projects.map((item, idx) => (
          <div className={idx === imageIndex ? "slide activeSlide" : "slide"}>
            <div class="container-slide">
            <img class="img-project" src={item.img} alt={item.img} />
            <div class="container-description-slider">
                <h5>{item.title}</h5>
                <label>{item.descrpiton}</label>
                <div class="container-tech-logos">
                    <div>
                    {item.techLogo.map((itemImg) => (
                                   <img class="logo-tech" src={itemImg} alt={itemImg} />
                    ))}
                    </div>
         
                <a href= {item.link} target="_blank">
                    Open Project
                </a>
                </div>
            </div>
            </div>
          </div>
        ))}
      </Slider>
            </div>
        </div>
        </section>
    )
}

