import { Navbar } from './Navbar';
import CLogo from '../imgs/skills/Csharp-logo.png';
import CSSLogo from '../imgs/skills/css-logo.png';
import HtmlLogo from '../imgs/skills/hmtl-logo.png';
import KnockoutLogo from '../imgs/skills/knockout-logo.svg';
import ReactLogo from '../imgs/skills/react-logo.png';
import BotstrapLogo from '../imgs/skills/bootstrap-logo.png';
import SQLLogo from '../imgs/skills/sql-logo.png';
import UmbracoLogo from '../imgs/skills/umbraco-logo.png';
import WebflowLogo from '../imgs/skills/webflow-logo.png';
import FigmaLogo from '../imgs/skills/figma-logo.png';

export const Experience = () => {
    return (
        <section id="experience">
            <Navbar />
            <div class="container-experience">
                <div class="container-center">
                    <div class="container-left">
                        <div class="title-container">
                            <label>Work</label>
                            <h4 class="tilte">Work</h4>
                        </div>
                        <div class="container-timeline">
                            <div class="timeline-block">
                                <div class="marker"></div>
                                <div class="timeline-content">
                                    <div class="timline-content-header">
                                        <h3>Full Stack Developer</h3>
                                        <span>Deventure, Timisoara</span>
                                    </div>
                                    <div class="timline-content-description-list">
                                        <div class="timline-content-description-item">
                                            <h5>Responsibilities:</h5>
                                            <span>- Software development and maintenance</span>
                                            <span>- Software integration and validation</span>
                                            <span>- Small scale software design</span>
                                            <span>- Analyze and Improve software performance</span>
                                        </div>
                                        <div class="timline-content-description-item">
                                            <h5>Achievements:</h5>
                                            <span>- Went through an intensive learning phase of software              
                                                  Back-end development</span>
                                            <span>- Improved development and design skills on software
                                                 development and its methodologies</span>
                                        </div>
                                    </div>
                                    <h5 class="date">Jun 2016 - Nov 2017</h5>
                                </div>
                            </div>
                            <div class="timeline-block">
                                <div class="marker"></div>
                                <div class="timeline-content">
                                    <div class="timline-content-header">
                                        <h3>Junior Software Developer</h3>
                                        <span>Nokia, Timisoara</span>
                                    </div>
                                    <div class="timline-content-description-list">
                                        <div class="timline-content-description-item">
                                            <h5>Responsibilities:</h5>
                                            <span>- Software development and maintenance</span>
                                            <span>- Software integration and validation</span>
                                            <span>- Small scale software design</span>
                                            <span>- Analyze and Improve software performance</span>
                                        </div>
                                        <div class="timline-content-description-item">
                                        <h5>Achievements:</h5>
                                            <span>- Went through an intensive learning phase of software              
                                                  Back-end development</span>
                                            <span>- Improved development and design skills on software
                                                 development and its methodologies</span>
                                        </div>
                                    </div>
                                    <h5 class="date">Jun 2016 - Nov 2017</h5>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="container-right">
                        <div class="title-container">
                            <label>EDUCATION</label>
                            <h4 class="tilte">EDUCATION</h4>
                        </div>
                        <div class="container-timeline">
                            <div class="container-timeline">
                                <div class="timeline-block">
                                    <div class="marker"></div>
                                    <div class="timeline-content">
                                        <div class="timline-content-header">
                                            <h3>BACHELOR’S DEGREE</h3>
                                        </div>
                                        <div class="timline-content-description-list">
                                            <div class="timline-content-description-item">
                                                <h5>Faculty Of Mathematics and Computer Science</h5>
                                                <h5>West University of Timisoara</h5>
                                            </div>

                                        </div>
                                        <h5 class="date">2014 - 2017</h5>
                                    </div>
                                </div>
                                <div class="timeline-block">
                                    <div class="marker"></div>
                                    <div class="timeline-content">
                                        <div class="timline-content-header">
                                            <h3>COLLEGE DEGREE</h3>
                                        </div>
                                        <div class="timline-content-description-list">
                                            <div class="timline-content-description-item">
                                                <h5>“Spiru Haret” College</h5>
                                                <h5>Tg. Jiu, Romania</h5>
                                            </div>
                                        </div>
                                        <h5 class="date">2014 - 2017</h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="container-sckills">
                        <div class="title-container">
                            <label>SKILLS</label>
                            <h4 class="tilte">SKILLS</h4>
                           
                        </div>
                        <div class="container-circle">
                            <div id="parent-circle">
                              <div class="circle item-1"><img src={CLogo} class="img-cirlce" alt="c#" /></div>
                              <div class="circle item-2"><img src={SQLLogo} class="img-cirlce" alt="sql" /></div>
                              <div class="circle item-3"><img src={BotstrapLogo} class="img-cirlce" alt="bootstrap" /></div>
                              <div class="circle item-4"><img src={HtmlLogo} class="img-cirlce" alt="html" /></div>
                              <div class="circle item-5"><img src={CSSLogo} class="img-cirlce" alt="css" /></div>
                              <div class="circle item-6"><img src={ReactLogo} class="img-cirlce" alt="react" /></div>
                              <div class="circle item-7"><img src={KnockoutLogo} class="img-cirlce" alt="Knockout" /></div>
                              <div class="circle item-8"><img src={WebflowLogo} class="img-cirlce" alt="webflow" /></div>
                              <div class="circle item-9"><img src={UmbracoLogo} class="img-cirlce" alt="umbraco" /></div>
                              <div class="circle item-10"><img src={FigmaLogo} class="img-cirlce" alt="figma" /></div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}