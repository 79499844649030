import { useState, useEffect } from 'react';
// import logo from '../log.svg';

export const Navbar = () => {
    const [activeLink, setActiveLink] = useState('home');
    const [scolled, seScrolled] = useState(false);

    useEffect(() => {
        const onScroll = () => {
            if (window.screenY > 50) {
                seScrolled(true);
            } else {
                seScrolled(false);
            }
        }

        return () => window.removeEventListener("scroll", onScroll)
    }, [])

    const onUpdateActiveLink = (value) => {
        setActiveLink(value);
    }

    return (
        <header  class='header-page' class={scolled ? "header-page scolled" : "header-page"}>
            <div class="logo-container">
                {/* <img src={logo} className="App-logo" alt="logo" /> */}
            </div>
            <div class="header-navbar">
                <a href="#home" color='inherit' class={activeLink === 'home' ? 'active-navbar' : ''} onClick={() => onUpdateActiveLink('home')} >Home</a>
                <a href="#about" color='inherit' class={activeLink === 'about' ? 'active-navbar' : ''} onClick={() => onUpdateActiveLink('about')}>About</a>
                <a href="#experience" color='inherit' class={activeLink === 'experience' ? 'active-navbar' : ''} onClick={() => onUpdateActiveLink('experience')}>Experience</a>
            </div>
        </header>
    )
}
