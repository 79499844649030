import { Navbar } from './Navbar';

export const About = () => {
    return (
        <section id="about">
        <Navbar />
        <div class="container-about">
            <div class="container-center">
                <div class="container-about-left">
                </div>
                <div class="container-about-row">
                    <div class="container-column-about">
                        <h4>NATIONALITY</h4>
                        <h4>CONTACT</h4>
                        <h4>SOCIAL</h4>
                    </div>
                    <div class="container-column-about">
                        <h4>NATIONALITY</h4>
                        <h4><a href="mailto:popovici.bogdan15@gmail.com">popovici.bogdan15@gmail.com</a></h4>
                        <h4>NATIONALITY</h4>
                    </div>
                </div>
                <div class="container-descrpiton">
                    <h4>I am Bogdan Popovici, a 28 years old software developer based in Romania. </h4>
                    <lable>
                        In the past years of experience I have discovered that I love to build things with technology, which has transformed from a simple passion into a full-time job. I am a fast learner and a logical person. Knowledge is fundamental in every part of my job and so I strongly believe that technology can and should enrich every single life this universe has to offer.</lable>

                    <label>
                        Find more about me by just exploring further this page.
                            </label>

                    <h4 >Kind Regards,</h4>
                    <h4 >BP</h4>
                </div>
            </div>
        </div>
        </section>
    )
}

