import 'materialize-css';
import './App.css';
import './AppScss.scss';
import { Home } from './components/Home';
import {About} from './components/About';
import {Experience} from './components/Experience';
import {Portofolio} from './components/Portofolio';


function App() {
  return (
    <div >
      <Home />
      <About />
      <Experience />
      <Portofolio />
    </div>
  );
}

export default App;
