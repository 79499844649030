import ImageHome from '../imgs/me.jpg';
import { Navbar } from './Navbar';

export const Home = () => {
    return (
        <section id="home">
            <Navbar />
            <div class="container-home container-center">
                <div class="container-descrpiton">
                    <h2>Hi!</h2>
                    <h2>Bogdan here.</h2>

                    <h4>I'M A</h4>
                    <h1>WEB DEVELOPER.</h1>
                    <hr />
                    <div class='container-bar'></div>
                </div>
                <div class="container-image">
                    <img src={ImageHome} class="img-home" alt="home" />
                </div>
            </div>
        </section>
    )
}